.thirdScreenCon{
    padding-top: 2vh;
    text-align: center;
    background-color: #60bc0f;
}
.cta-area{
    background: #60bc0f;
    background-size: cover;
    text-align: center;
    color: #fff;
    z-index: 1;
    padding: 80px;
}
