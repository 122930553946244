.links{
    font-size: 13px;
    font-weight: 500;
    line-height: 50px;
    text-transform: uppercase;
    color: #fff;
    padding: 0;
    display: inline-block;
}

.logo{
    width: 180px;
    height: 150px;
}
.background{
    /*background-color: #222;*/
}
.active{
    color: #60bc0f;
}
.navbar-toggler-icon{
    color: #fff !important;
}
