.about_right{
    /*padding: 50px 30px;*/
    background: #fff;
   padding-top: 70px;
    z-index: 2;
    box-shadow: 0 5px 40px rgba(153,153,153,.2);
}


.image{
    width: 300px;
}

.about_left{
    padding-right: 0;
    padding-left: 30px;
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
   .image{
       width: 300px;
   }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
    .image{
        width: 300px;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
    .image{
        width: 300px;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .image{
        width: 100%;
    }

}

