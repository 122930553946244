.primary_btn{
    display: inline-block;
    background: #60bc0f;
    padding: 0 50px;
    color: #fff;
    font-family: roboto,sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 48px;
    border: 1px solid #60bc0f;
    border-radius: 0;
    outline: none!important;
    box-shadow: none!important;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 300ms linear 0s;
}

.primary_btn.yellow_btn{
    background: #fdbb00!important;
    border: 1px solid #fdbb00!important;
    color: #000;
}

.firstScreenCon{
    padding-top: 5vh;
    height: 80vh;
}

.compText{
    max-width: 680px;
    margin: 0 auto 45px;
    text-align: center;
}

.compText1{
    font-size: 50px;
    text-transform: uppercase;
    color: #fdbb00;
}

.compText2{
    color: #fff;
    margin-top: 0;
    font-size: 50px;
    font-weight: 700;
    font-family: poppins,sans-serif;
    line-height: 70px;
    text-transform: uppercase;
    margin-bottom: 20px;

}
.compText3{
    color: #f9f9ff;
    max-width: 680px;
    margin: 0 auto 45px;
}
