.footer-area {
    background: #091b27;
    text-align: left;
}

.section_gap {
    padding: 130px 0;
}

.footerMain {
    line-height: 25px;
    font-size: 15px;
    font-family: roboto, sans-serif;
    font-weight: 400;
    color: #777;
}

.footer_title.large_title {
    font-size: 36px;
    font-weight: 600;
}

.tp_widgets .list li a {
    color: #777;
    font-family: roboto, sans-serif;
}

.instafeed {
    margin-left: -5px;
    margin-right: -5px;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.footer_title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    font-family: poppins, sans-serif;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 28px;
}

.single-footer-widget p {
    margin-bottom: 15px;
}

.footer-bottom {
    background: #071721;
    padding: 32px;
}

.footer-bottom .footer-text {
    color: #fff;
    margin-bottom: 0;
}

.contactUsHeading{
    text-align: left;
    margin-top: 20px;
    color: #60bc0f;
    margin-bottom: 0;
}

.contactUsDetails{
    text-align: left;
    margin-top: 20px;
color:rgb(119, 119, 119);
    margin-bottom: 0;
}
