.volunteer{
    padding: 100px 0px;
    background-color: #f9fafc;
}
.vHeading{
    text-align: center;
    margin-bottom: 80px;
}
.iconStyle{
    font-size: 14px;
    color: #acadb0;
    margin-right: 20px;
    text-align: center;
    height: 30px;
    width: 30px;
    background: 0 0;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    border-radius: 20px;
    border: 1px solid #777;
    transition: all 300ms linear 0s;
    text-decoration: none;
}
.iconColor{
    color: #acadb0;
}
.fa{
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.iconActive{
    background: #60bc0f;
    color: #fff;
    border: 1px solid #60bc0f;
}
.iconActive i {
    color: #fff;
}
.card-img-top{
    width:286px;
    height:286px;
}